import { default as activity_45reportrNGQNSekOWMeta } from "/app/pages/agent-visit/activity-report.vue?macro=true";
import { default as _91id_93fJGQSk0D1gMeta } from "/app/pages/agent-visit/detail/[id].vue?macro=true";
import { default as indexwJhyuydRiiMeta } from "/app/pages/agent-visit/index.vue?macro=true";
import { default as emergency_45contactbm20WDJetNMeta } from "/app/pages/application-management/detail/[id]/emergency-contact.vue?macro=true";
import { default as financingrPg9sPBgwnMeta } from "/app/pages/application-management/detail/[id]/financing.vue?macro=true";
import { default as indexhSlSlJqZNiMeta } from "/app/pages/application-management/detail/[id]/index.vue?macro=true";
import { default as job_45and_45bank_45accountHfbssfEHoGMeta } from "/app/pages/application-management/detail/[id]/job-and-bank-account.vue?macro=true";
import { default as personal_45dataCl8ak8vwpyMeta } from "/app/pages/application-management/detail/[id]/personal-data.vue?macro=true";
import { default as indexOgJCbTWy2HMeta } from "/app/pages/application-management/index.vue?macro=true";
import { default as index1CMGEIaizrMeta } from "/app/pages/dedicated-cmo/edit/[id]/index.vue?macro=true";
import { default as indexiKHNVEucQAMeta } from "/app/pages/dedicated-cmo/index.vue?macro=true";
import { default as _91code_93kGvH4Xen5AMeta } from "/app/pages/error/[code].vue?macro=true";
import { default as indextBMhLduifHMeta } from "/app/pages/expired-link/index.vue?macro=true";
import { default as datatables_45exampleKq7l3oTlYDMeta } from "/app/pages/guide-example/datatables-example.vue?macro=true";
import { default as datatablesZo1huotea8Meta } from "/app/pages/guide-example/datatables.vue?macro=true";
import { default as sm_45selectj370a9iRkGMeta } from "/app/pages/guide-example/sm-select.vue?macro=true";
import { default as test_45dslJaMFpK8JJ7Meta } from "/app/pages/guide-example/test-dsl.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_939Z4KyEelD4Meta } from "/app/pages/kmb/agent-registration/approval/[id].vue?macro=true";
import { default as _91id_93HaUgymRIdKMeta } from "/app/pages/kmb/agent-registration/detail/[id].vue?macro=true";
import { default as indexldaiQebJa2Meta } from "/app/pages/kmb/agent-registration/index.vue?macro=true";
import { default as _91id_93GiTXPYKIrTMeta } from "/app/pages/kmb/agent-registration/review/[id].vue?macro=true";
import { default as create6boYLrDHywMeta } from "/app/pages/kmb/customer-management/create.vue?macro=true";
import { default as _91id_93iOIoDIOV8jMeta } from "/app/pages/kmb/customer-management/detail/[status]/[id].vue?macro=true";
import { default as indexk5hUGiUqrsMeta } from "/app/pages/kmb/customer-management/index.vue?macro=true";
import { default as _91name_938kXlQhhotRMeta } from "/app/pages/kmb/customer-management/pos/[id]/[name].vue?macro=true";
import { default as indexA65RfPYnhUMeta } from "/app/pages/kmb/dashboard/index.vue?macro=true";
import { default as _91item_93EExv7HPcI8Meta } from "/app/pages/kmb/dashboard/list/[filter]/[item].vue?macro=true";
import { default as _91id_93ILDZ2MqXB4Meta } from "/app/pages/kmob/agent-registration/approval/[id].vue?macro=true";
import { default as indexZUrDgVSsqCMeta } from "/app/pages/kmob/agent-registration/detail/[id]/index.vue?macro=true";
import { default as indexq5Fk6m6FvZMeta } from "/app/pages/kmob/agent-registration/index.vue?macro=true";
import { default as _91id_93x4jV1XVXUXMeta } from "/app/pages/kmob/agent-registration/review/[id].vue?macro=true";
import { default as indexhEIT5wunNzMeta } from "/app/pages/kmob/agent-visit/index.vue?macro=true";
import { default as data_45personal0FOY7svFaVMeta } from "/app/pages/kmob/akk/detail/[id]/data-personal.vue?macro=true";
import { default as emergency_45contactsFVxOWqnSwMeta } from "/app/pages/kmob/akk/detail/[id]/emergency-contact.vue?macro=true";
import { default as fotoDwkLOvLQK3Meta } from "/app/pages/kmob/akk/detail/[id]/foto.vue?macro=true";
import { default as incomeyMkG6e89ywMeta } from "/app/pages/kmob/akk/detail/[id]/income.vue?macro=true";
import { default as indexDSZLjvRIUzMeta } from "/app/pages/kmob/akk/detail/[id]/index.vue?macro=true";
import { default as jobUZJ7WNQnNOMeta } from "/app/pages/kmob/akk/detail/[id]/job.vue?macro=true";
import { default as recommendationXA0LDGYdyTMeta } from "/app/pages/kmob/akk/detail/[id]/recommendation.vue?macro=true";
import { default as silent_45data_45surveyA6M16UxIOXMeta } from "/app/pages/kmob/akk/detail/[id]/silent-data-survey.vue?macro=true";
import { default as silent_45observationTVrYqtLWGZMeta } from "/app/pages/kmob/akk/detail/[id]/silent-observation.vue?macro=true";
import { default as silent_45source_45informationVUEehl5d7UMeta } from "/app/pages/kmob/akk/detail/[id]/silent-source-information.vue?macro=true";
import { default as indexo3usIcKwSZMeta } from "/app/pages/kmob/akk/index.vue?macro=true";
import { default as data_45personalk5jASK7RAbMeta } from "/app/pages/kmob/apk/detail/[id]/data-personal.vue?macro=true";
import { default as emergency_45contactdIRvQIO3btMeta } from "/app/pages/kmob/apk/detail/[id]/emergency-contact.vue?macro=true";
import { default as financingTiwWaOQ6MiMeta } from "/app/pages/kmob/apk/detail/[id]/financing.vue?macro=true";
import { default as formpv_45data_45analisa_45penghasilanHPo0VVzMBYMeta } from "/app/pages/kmob/apk/detail/[id]/formpv-data-analisa-penghasilan.vue?macro=true";
import { default as formpv_45data_45kontak_45darurats5kR99Iu6lMeta } from "/app/pages/kmob/apk/detail/[id]/formpv-data-kontak-darurat.vue?macro=true";
import { default as formpv_45data_45pekerjaanBesZTvlCzLMeta } from "/app/pages/kmob/apk/detail/[id]/formpv-data-pekerjaan.vue?macro=true";
import { default as formpv_45data_45pembiayaanlSgF7cILSRMeta } from "/app/pages/kmob/apk/detail/[id]/formpv-data-pembiayaan.vue?macro=true";
import { default as formpv_45data_45personalJYANNabdeDMeta } from "/app/pages/kmob/apk/detail/[id]/formpv-data-personal.vue?macro=true";
import { default as fotokNXVD8ttPoMeta } from "/app/pages/kmob/apk/detail/[id]/foto.vue?macro=true";
import { default as indexLYmc52Ofn3Meta } from "/app/pages/kmob/apk/detail/[id]/index.vue?macro=true";
import { default as job4hmJoI3sPFMeta } from "/app/pages/kmob/apk/detail/[id]/job.vue?macro=true";
import { default as addresso9pXBw3aAmMeta } from "/app/pages/kmob/apk/edit/[id]/emergency-contact/address.vue?macro=true";
import { default as contactp84WDfYnpjMeta } from "/app/pages/kmob/apk/edit/[id]/emergency-contact/contact.vue?macro=true";
import { default as bank_45accountxskfGQRalmMeta } from "/app/pages/kmob/apk/edit/[id]/job-and-bank-account/bank-account.vue?macro=true";
import { default as company_45addressQqIgcGQhpLMeta } from "/app/pages/kmob/apk/edit/[id]/job-and-bank-account/company-address.vue?macro=true";
import { default as jobGzKHOMCyL8Meta } from "/app/pages/kmob/apk/edit/[id]/job-and-bank-account/job.vue?macro=true";
import { default as indexDxtBHLghO6Meta } from "/app/pages/kmob/apk/index.vue?macro=true";
import { default as _91id_93KETEiltJ6GMeta } from "/app/pages/kmob/customer-management/detail/[status]/[id].vue?macro=true";
import { default as indexNfg7x5arQEMeta } from "/app/pages/kmob/customer-management/index.vue?macro=true";
import { default as index5nm5o6xmt6Meta } from "/app/pages/kmob/dashboard/index.vue?macro=true";
import { default as contoh_45validasi5rxiiyjNyrMeta } from "/app/pages/kmob/order-form/contoh-validasi.vue?macro=true";
import { default as assetT1jLbBAkNSMeta } from "/app/pages/kmob/order-form/create/asset.vue?macro=true";
import { default as documentlFuKpBumcEMeta } from "/app/pages/kmob/order-form/create/document.vue?macro=true";
import { default as indexqA45o1kNVSMeta } from "/app/pages/kmob/order-form/create/index.vue?macro=true";
import { default as _91id_93etYV5xEOv9Meta } from "/app/pages/kmob/order-form/detail/[id].vue?macro=true";
import { default as indexXtMA6vXu0VMeta } from "/app/pages/kmob/order-form/index.vue?macro=true";
import { default as _91id_931KambrOEqwMeta } from "/app/pages/kmob/order-form/review/[id].vue?macro=true";
import { default as indexOQLCfhnYBUMeta } from "/app/pages/kmob/taksasi/detail/[id]/index.vue?macro=true";
import { default as indexPMOI6gqxbEMeta } from "/app/pages/kmob/taksasi/detail/[id]/link-confirmation/index.vue?macro=true";
import { default as indexuvYFmgVDnuMeta } from "/app/pages/kmob/taksasi/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as _91id_93I44RHBuTq8Meta } from "/app/pages/nwg/customer-management/detail/[status]/[id].vue?macro=true";
import { default as indexfCLKVY6bKlMeta } from "/app/pages/nwg/customer-management/index.vue?macro=true";
import { default as indexiXJUNZmZBAMeta } from "/app/pages/nwg/dashboard/index.vue?macro=true";
import { default as _91id_93wcPby857ZjMeta } from "/app/pages/nwg/sales-incentive/detail/[id].vue?macro=true";
import { default as indexU0xilijXV4Meta } from "/app/pages/nwg/sales-incentive/index.vue?macro=true";
import { default as _91token_931L7mjHLLYGMeta } from "/app/pages/reset-password/[id]/[token].vue?macro=true";
import { default as _91id_93Pr2Zd8r6Y6Meta } from "/app/pages/role-management/[actions]/[id].vue?macro=true";
import { default as createKpR9w070y5Meta } from "/app/pages/role-management/create.vue?macro=true";
import { default as indexIbit2heM6hMeta } from "/app/pages/role-management/index.vue?macro=true";
import { default as index3CIxsOSFhQMeta } from "/app/pages/select-lob/index.vue?macro=true";
import { default as createnQhgsWmMC3Meta } from "/app/pages/submission-form/create.vue?macro=true";
import { default as _91id_93oq4neSi7JEMeta } from "/app/pages/submission-form/detail/[id].vue?macro=true";
import { default as indexToOyMPglqDMeta } from "/app/pages/submission-form/index.vue?macro=true";
import { default as _91id_93GXvhXdMk8aMeta } from "/app/pages/submission-form/review-kpm/[id].vue?macro=true";
import { default as _91id_93STc4pdMMvTMeta } from "/app/pages/submission-form/review/[id].vue?macro=true";
import { default as emergency_45contactIMR2DK58DhMeta } from "/app/pages/survey-management/detail/[id]/[form]/[branch_id]/emergency-contact.vue?macro=true";
import { default as incomeNYYcMrYNOyMeta } from "/app/pages/survey-management/detail/[id]/[form]/[branch_id]/income.vue?macro=true";
import { default as job9WT6SGHJIXMeta } from "/app/pages/survey-management/detail/[id]/[form]/[branch_id]/job.vue?macro=true";
import { default as personal_45dataVy8qcPNQMHMeta } from "/app/pages/survey-management/detail/[id]/[form]/[branch_id]/personal-data.vue?macro=true";
import { default as recommendationHDFSKjUobpMeta } from "/app/pages/survey-management/detail/[id]/[form]/[branch_id]/recommendation.vue?macro=true";
import { default as assign_45editqUAURs2NdOMeta } from "/app/pages/survey-management/detail/[id]/[form]/assign-edit.vue?macro=true";
import { default as recalculategwb3zneh3TMeta } from "/app/pages/survey-management/detail/[id]/[form]/recalculate.vue?macro=true";
import { default as indexcF07N7W4vnMeta } from "/app/pages/survey-management/detail/[id]/index.vue?macro=true";
import { default as indexKcr206D5SKMeta } from "/app/pages/survey-management/index.vue?macro=true";
import { default as createiLWWeZ7HCDMeta } from "/app/pages/user-management/create.vue?macro=true";
import { default as _91id_93myZCKEYcK8Meta } from "/app/pages/user-management/edit/[id].vue?macro=true";
import { default as indexZCMpMPW3YaMeta } from "/app/pages/user-management/index.vue?macro=true";
import { default as _91token_939iIWHf4iSZMeta } from "/app/pages/verification-email/[id]/[token].vue?macro=true";
export default [
  {
    name: activity_45reportrNGQNSekOWMeta?.name ?? "agent-visit-activity-report",
    path: activity_45reportrNGQNSekOWMeta?.path ?? "/agent-visit/activity-report",
    meta: activity_45reportrNGQNSekOWMeta || {},
    alias: activity_45reportrNGQNSekOWMeta?.alias || [],
    redirect: activity_45reportrNGQNSekOWMeta?.redirect,
    component: () => import("/app/pages/agent-visit/activity-report.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fJGQSk0D1gMeta?.name ?? "agent-visit-detail-id",
    path: _91id_93fJGQSk0D1gMeta?.path ?? "/agent-visit/detail/:id()",
    meta: _91id_93fJGQSk0D1gMeta || {},
    alias: _91id_93fJGQSk0D1gMeta?.alias || [],
    redirect: _91id_93fJGQSk0D1gMeta?.redirect,
    component: () => import("/app/pages/agent-visit/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwJhyuydRiiMeta?.name ?? "agent-visit",
    path: indexwJhyuydRiiMeta?.path ?? "/agent-visit",
    meta: indexwJhyuydRiiMeta || {},
    alias: indexwJhyuydRiiMeta?.alias || [],
    redirect: indexwJhyuydRiiMeta?.redirect,
    component: () => import("/app/pages/agent-visit/index.vue").then(m => m.default || m)
  },
  {
    name: emergency_45contactbm20WDJetNMeta?.name ?? "application-management-detail-id-emergency-contact",
    path: emergency_45contactbm20WDJetNMeta?.path ?? "/application-management/detail/:id()/emergency-contact",
    meta: emergency_45contactbm20WDJetNMeta || {},
    alias: emergency_45contactbm20WDJetNMeta?.alias || [],
    redirect: emergency_45contactbm20WDJetNMeta?.redirect,
    component: () => import("/app/pages/application-management/detail/[id]/emergency-contact.vue").then(m => m.default || m)
  },
  {
    name: financingrPg9sPBgwnMeta?.name ?? "application-management-detail-id-financing",
    path: financingrPg9sPBgwnMeta?.path ?? "/application-management/detail/:id()/financing",
    meta: financingrPg9sPBgwnMeta || {},
    alias: financingrPg9sPBgwnMeta?.alias || [],
    redirect: financingrPg9sPBgwnMeta?.redirect,
    component: () => import("/app/pages/application-management/detail/[id]/financing.vue").then(m => m.default || m)
  },
  {
    name: indexhSlSlJqZNiMeta?.name ?? "application-management-detail-id",
    path: indexhSlSlJqZNiMeta?.path ?? "/application-management/detail/:id()",
    meta: indexhSlSlJqZNiMeta || {},
    alias: indexhSlSlJqZNiMeta?.alias || [],
    redirect: indexhSlSlJqZNiMeta?.redirect,
    component: () => import("/app/pages/application-management/detail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: job_45and_45bank_45accountHfbssfEHoGMeta?.name ?? "application-management-detail-id-job-and-bank-account",
    path: job_45and_45bank_45accountHfbssfEHoGMeta?.path ?? "/application-management/detail/:id()/job-and-bank-account",
    meta: job_45and_45bank_45accountHfbssfEHoGMeta || {},
    alias: job_45and_45bank_45accountHfbssfEHoGMeta?.alias || [],
    redirect: job_45and_45bank_45accountHfbssfEHoGMeta?.redirect,
    component: () => import("/app/pages/application-management/detail/[id]/job-and-bank-account.vue").then(m => m.default || m)
  },
  {
    name: personal_45dataCl8ak8vwpyMeta?.name ?? "application-management-detail-id-personal-data",
    path: personal_45dataCl8ak8vwpyMeta?.path ?? "/application-management/detail/:id()/personal-data",
    meta: personal_45dataCl8ak8vwpyMeta || {},
    alias: personal_45dataCl8ak8vwpyMeta?.alias || [],
    redirect: personal_45dataCl8ak8vwpyMeta?.redirect,
    component: () => import("/app/pages/application-management/detail/[id]/personal-data.vue").then(m => m.default || m)
  },
  {
    name: indexOgJCbTWy2HMeta?.name ?? "application-management",
    path: indexOgJCbTWy2HMeta?.path ?? "/application-management",
    meta: indexOgJCbTWy2HMeta || {},
    alias: indexOgJCbTWy2HMeta?.alias || [],
    redirect: indexOgJCbTWy2HMeta?.redirect,
    component: () => import("/app/pages/application-management/index.vue").then(m => m.default || m)
  },
  {
    name: index1CMGEIaizrMeta?.name ?? "dedicated-cmo-edit-id",
    path: index1CMGEIaizrMeta?.path ?? "/dedicated-cmo/edit/:id()",
    meta: index1CMGEIaizrMeta || {},
    alias: index1CMGEIaizrMeta?.alias || [],
    redirect: index1CMGEIaizrMeta?.redirect,
    component: () => import("/app/pages/dedicated-cmo/edit/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiKHNVEucQAMeta?.name ?? "dedicated-cmo",
    path: indexiKHNVEucQAMeta?.path ?? "/dedicated-cmo",
    meta: indexiKHNVEucQAMeta || {},
    alias: indexiKHNVEucQAMeta?.alias || [],
    redirect: indexiKHNVEucQAMeta?.redirect,
    component: () => import("/app/pages/dedicated-cmo/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93kGvH4Xen5AMeta?.name ?? "error-code",
    path: _91code_93kGvH4Xen5AMeta?.path ?? "/error/:code()",
    meta: _91code_93kGvH4Xen5AMeta || {},
    alias: _91code_93kGvH4Xen5AMeta?.alias || [],
    redirect: _91code_93kGvH4Xen5AMeta?.redirect,
    component: () => import("/app/pages/error/[code].vue").then(m => m.default || m)
  },
  {
    name: indextBMhLduifHMeta?.name ?? "expired-link",
    path: indextBMhLduifHMeta?.path ?? "/expired-link",
    meta: indextBMhLduifHMeta || {},
    alias: indextBMhLduifHMeta?.alias || [],
    redirect: indextBMhLduifHMeta?.redirect,
    component: () => import("/app/pages/expired-link/index.vue").then(m => m.default || m)
  },
  {
    name: datatables_45exampleKq7l3oTlYDMeta?.name ?? "guide-example-datatables-example",
    path: datatables_45exampleKq7l3oTlYDMeta?.path ?? "/guide-example/datatables-example",
    meta: datatables_45exampleKq7l3oTlYDMeta || {},
    alias: datatables_45exampleKq7l3oTlYDMeta?.alias || [],
    redirect: datatables_45exampleKq7l3oTlYDMeta?.redirect,
    component: () => import("/app/pages/guide-example/datatables-example.vue").then(m => m.default || m)
  },
  {
    name: datatablesZo1huotea8Meta?.name ?? "guide-example-datatables",
    path: datatablesZo1huotea8Meta?.path ?? "/guide-example/datatables",
    meta: datatablesZo1huotea8Meta || {},
    alias: datatablesZo1huotea8Meta?.alias || [],
    redirect: datatablesZo1huotea8Meta?.redirect,
    component: () => import("/app/pages/guide-example/datatables.vue").then(m => m.default || m)
  },
  {
    name: sm_45selectj370a9iRkGMeta?.name ?? "guide-example-sm-select",
    path: sm_45selectj370a9iRkGMeta?.path ?? "/guide-example/sm-select",
    meta: sm_45selectj370a9iRkGMeta || {},
    alias: sm_45selectj370a9iRkGMeta?.alias || [],
    redirect: sm_45selectj370a9iRkGMeta?.redirect,
    component: () => import("/app/pages/guide-example/sm-select.vue").then(m => m.default || m)
  },
  {
    name: test_45dslJaMFpK8JJ7Meta?.name ?? "guide-example-test-dsl",
    path: test_45dslJaMFpK8JJ7Meta?.path ?? "/guide-example/test-dsl",
    meta: test_45dslJaMFpK8JJ7Meta || {},
    alias: test_45dslJaMFpK8JJ7Meta?.alias || [],
    redirect: test_45dslJaMFpK8JJ7Meta?.redirect,
    component: () => import("/app/pages/guide-example/test-dsl.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939Z4KyEelD4Meta?.name ?? "kmb-agent-registration-approval-id",
    path: _91id_939Z4KyEelD4Meta?.path ?? "/kmb/agent-registration/approval/:id()",
    meta: _91id_939Z4KyEelD4Meta || {},
    alias: _91id_939Z4KyEelD4Meta?.alias || [],
    redirect: _91id_939Z4KyEelD4Meta?.redirect,
    component: () => import("/app/pages/kmb/agent-registration/approval/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HaUgymRIdKMeta?.name ?? "kmb-agent-registration-detail-id",
    path: _91id_93HaUgymRIdKMeta?.path ?? "/kmb/agent-registration/detail/:id()",
    meta: _91id_93HaUgymRIdKMeta || {},
    alias: _91id_93HaUgymRIdKMeta?.alias || [],
    redirect: _91id_93HaUgymRIdKMeta?.redirect,
    component: () => import("/app/pages/kmb/agent-registration/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexldaiQebJa2Meta?.name ?? "kmb-agent-registration",
    path: indexldaiQebJa2Meta?.path ?? "/kmb/agent-registration",
    meta: indexldaiQebJa2Meta || {},
    alias: indexldaiQebJa2Meta?.alias || [],
    redirect: indexldaiQebJa2Meta?.redirect,
    component: () => import("/app/pages/kmb/agent-registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GiTXPYKIrTMeta?.name ?? "kmb-agent-registration-review-id",
    path: _91id_93GiTXPYKIrTMeta?.path ?? "/kmb/agent-registration/review/:id()",
    meta: _91id_93GiTXPYKIrTMeta || {},
    alias: _91id_93GiTXPYKIrTMeta?.alias || [],
    redirect: _91id_93GiTXPYKIrTMeta?.redirect,
    component: () => import("/app/pages/kmb/agent-registration/review/[id].vue").then(m => m.default || m)
  },
  {
    name: create6boYLrDHywMeta?.name ?? "kmb-customer-management-create",
    path: create6boYLrDHywMeta?.path ?? "/kmb/customer-management/create",
    meta: create6boYLrDHywMeta || {},
    alias: create6boYLrDHywMeta?.alias || [],
    redirect: create6boYLrDHywMeta?.redirect,
    component: () => import("/app/pages/kmb/customer-management/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iOIoDIOV8jMeta?.name ?? "kmb-customer-management-detail-status-id",
    path: _91id_93iOIoDIOV8jMeta?.path ?? "/kmb/customer-management/detail/:status()/:id()",
    meta: _91id_93iOIoDIOV8jMeta || {},
    alias: _91id_93iOIoDIOV8jMeta?.alias || [],
    redirect: _91id_93iOIoDIOV8jMeta?.redirect,
    component: () => import("/app/pages/kmb/customer-management/detail/[status]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexk5hUGiUqrsMeta?.name ?? "kmb-customer-management",
    path: indexk5hUGiUqrsMeta?.path ?? "/kmb/customer-management",
    meta: indexk5hUGiUqrsMeta || {},
    alias: indexk5hUGiUqrsMeta?.alias || [],
    redirect: indexk5hUGiUqrsMeta?.redirect,
    component: () => import("/app/pages/kmb/customer-management/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_938kXlQhhotRMeta?.name ?? "kmb-customer-management-pos-id-name",
    path: _91name_938kXlQhhotRMeta?.path ?? "/kmb/customer-management/pos/:id()/:name()",
    meta: _91name_938kXlQhhotRMeta || {},
    alias: _91name_938kXlQhhotRMeta?.alias || [],
    redirect: _91name_938kXlQhhotRMeta?.redirect,
    component: () => import("/app/pages/kmb/customer-management/pos/[id]/[name].vue").then(m => m.default || m)
  },
  {
    name: indexA65RfPYnhUMeta?.name ?? "kmb-dashboard",
    path: indexA65RfPYnhUMeta?.path ?? "/kmb/dashboard",
    meta: indexA65RfPYnhUMeta || {},
    alias: indexA65RfPYnhUMeta?.alias || [],
    redirect: indexA65RfPYnhUMeta?.redirect,
    component: () => import("/app/pages/kmb/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91item_93EExv7HPcI8Meta?.name ?? "kmb-dashboard-list-filter-item",
    path: _91item_93EExv7HPcI8Meta?.path ?? "/kmb/dashboard/list/:filter()/:item()",
    meta: _91item_93EExv7HPcI8Meta || {},
    alias: _91item_93EExv7HPcI8Meta?.alias || [],
    redirect: _91item_93EExv7HPcI8Meta?.redirect,
    component: () => import("/app/pages/kmb/dashboard/list/[filter]/[item].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ILDZ2MqXB4Meta?.name ?? "kmob-agent-registration-approval-id",
    path: _91id_93ILDZ2MqXB4Meta?.path ?? "/kmob/agent-registration/approval/:id()",
    meta: _91id_93ILDZ2MqXB4Meta || {},
    alias: _91id_93ILDZ2MqXB4Meta?.alias || [],
    redirect: _91id_93ILDZ2MqXB4Meta?.redirect,
    component: () => import("/app/pages/kmob/agent-registration/approval/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZUrDgVSsqCMeta?.name ?? "kmob-agent-registration-detail-id",
    path: indexZUrDgVSsqCMeta?.path ?? "/kmob/agent-registration/detail/:id()",
    meta: indexZUrDgVSsqCMeta || {},
    alias: indexZUrDgVSsqCMeta?.alias || [],
    redirect: indexZUrDgVSsqCMeta?.redirect,
    component: () => import("/app/pages/kmob/agent-registration/detail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexq5Fk6m6FvZMeta?.name ?? "kmob-agent-registration",
    path: indexq5Fk6m6FvZMeta?.path ?? "/kmob/agent-registration",
    meta: indexq5Fk6m6FvZMeta || {},
    alias: indexq5Fk6m6FvZMeta?.alias || [],
    redirect: indexq5Fk6m6FvZMeta?.redirect,
    component: () => import("/app/pages/kmob/agent-registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93x4jV1XVXUXMeta?.name ?? "kmob-agent-registration-review-id",
    path: _91id_93x4jV1XVXUXMeta?.path ?? "/kmob/agent-registration/review/:id()",
    meta: _91id_93x4jV1XVXUXMeta || {},
    alias: _91id_93x4jV1XVXUXMeta?.alias || [],
    redirect: _91id_93x4jV1XVXUXMeta?.redirect,
    component: () => import("/app/pages/kmob/agent-registration/review/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhEIT5wunNzMeta?.name ?? "kmob-agent-visit",
    path: indexhEIT5wunNzMeta?.path ?? "/kmob/agent-visit",
    meta: indexhEIT5wunNzMeta || {},
    alias: indexhEIT5wunNzMeta?.alias || [],
    redirect: indexhEIT5wunNzMeta?.redirect,
    component: () => import("/app/pages/kmob/agent-visit/index.vue").then(m => m.default || m)
  },
  {
    name: data_45personal0FOY7svFaVMeta?.name ?? "kmob-akk-detail-id-data-personal",
    path: data_45personal0FOY7svFaVMeta?.path ?? "/kmob/akk/detail/:id()/data-personal",
    meta: data_45personal0FOY7svFaVMeta || {},
    alias: data_45personal0FOY7svFaVMeta?.alias || [],
    redirect: data_45personal0FOY7svFaVMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/data-personal.vue").then(m => m.default || m)
  },
  {
    name: emergency_45contactsFVxOWqnSwMeta?.name ?? "kmob-akk-detail-id-emergency-contact",
    path: emergency_45contactsFVxOWqnSwMeta?.path ?? "/kmob/akk/detail/:id()/emergency-contact",
    meta: emergency_45contactsFVxOWqnSwMeta || {},
    alias: emergency_45contactsFVxOWqnSwMeta?.alias || [],
    redirect: emergency_45contactsFVxOWqnSwMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/emergency-contact.vue").then(m => m.default || m)
  },
  {
    name: fotoDwkLOvLQK3Meta?.name ?? "kmob-akk-detail-id-foto",
    path: fotoDwkLOvLQK3Meta?.path ?? "/kmob/akk/detail/:id()/foto",
    meta: fotoDwkLOvLQK3Meta || {},
    alias: fotoDwkLOvLQK3Meta?.alias || [],
    redirect: fotoDwkLOvLQK3Meta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/foto.vue").then(m => m.default || m)
  },
  {
    name: incomeyMkG6e89ywMeta?.name ?? "kmob-akk-detail-id-income",
    path: incomeyMkG6e89ywMeta?.path ?? "/kmob/akk/detail/:id()/income",
    meta: incomeyMkG6e89ywMeta || {},
    alias: incomeyMkG6e89ywMeta?.alias || [],
    redirect: incomeyMkG6e89ywMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/income.vue").then(m => m.default || m)
  },
  {
    name: indexDSZLjvRIUzMeta?.name ?? "kmob-akk-detail-id",
    path: indexDSZLjvRIUzMeta?.path ?? "/kmob/akk/detail/:id()",
    meta: indexDSZLjvRIUzMeta || {},
    alias: indexDSZLjvRIUzMeta?.alias || [],
    redirect: indexDSZLjvRIUzMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: jobUZJ7WNQnNOMeta?.name ?? "kmob-akk-detail-id-job",
    path: jobUZJ7WNQnNOMeta?.path ?? "/kmob/akk/detail/:id()/job",
    meta: jobUZJ7WNQnNOMeta || {},
    alias: jobUZJ7WNQnNOMeta?.alias || [],
    redirect: jobUZJ7WNQnNOMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/job.vue").then(m => m.default || m)
  },
  {
    name: recommendationXA0LDGYdyTMeta?.name ?? "kmob-akk-detail-id-recommendation",
    path: recommendationXA0LDGYdyTMeta?.path ?? "/kmob/akk/detail/:id()/recommendation",
    meta: recommendationXA0LDGYdyTMeta || {},
    alias: recommendationXA0LDGYdyTMeta?.alias || [],
    redirect: recommendationXA0LDGYdyTMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/recommendation.vue").then(m => m.default || m)
  },
  {
    name: silent_45data_45surveyA6M16UxIOXMeta?.name ?? "kmob-akk-detail-id-silent-data-survey",
    path: silent_45data_45surveyA6M16UxIOXMeta?.path ?? "/kmob/akk/detail/:id()/silent-data-survey",
    meta: silent_45data_45surveyA6M16UxIOXMeta || {},
    alias: silent_45data_45surveyA6M16UxIOXMeta?.alias || [],
    redirect: silent_45data_45surveyA6M16UxIOXMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/silent-data-survey.vue").then(m => m.default || m)
  },
  {
    name: silent_45observationTVrYqtLWGZMeta?.name ?? "kmob-akk-detail-id-silent-observation",
    path: silent_45observationTVrYqtLWGZMeta?.path ?? "/kmob/akk/detail/:id()/silent-observation",
    meta: silent_45observationTVrYqtLWGZMeta || {},
    alias: silent_45observationTVrYqtLWGZMeta?.alias || [],
    redirect: silent_45observationTVrYqtLWGZMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/silent-observation.vue").then(m => m.default || m)
  },
  {
    name: silent_45source_45informationVUEehl5d7UMeta?.name ?? "kmob-akk-detail-id-silent-source-information",
    path: silent_45source_45informationVUEehl5d7UMeta?.path ?? "/kmob/akk/detail/:id()/silent-source-information",
    meta: silent_45source_45informationVUEehl5d7UMeta || {},
    alias: silent_45source_45informationVUEehl5d7UMeta?.alias || [],
    redirect: silent_45source_45informationVUEehl5d7UMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/detail/[id]/silent-source-information.vue").then(m => m.default || m)
  },
  {
    name: indexo3usIcKwSZMeta?.name ?? "kmob-akk",
    path: indexo3usIcKwSZMeta?.path ?? "/kmob/akk",
    meta: indexo3usIcKwSZMeta || {},
    alias: indexo3usIcKwSZMeta?.alias || [],
    redirect: indexo3usIcKwSZMeta?.redirect,
    component: () => import("/app/pages/kmob/akk/index.vue").then(m => m.default || m)
  },
  {
    name: data_45personalk5jASK7RAbMeta?.name ?? "kmob-apk-detail-id-data-personal",
    path: data_45personalk5jASK7RAbMeta?.path ?? "/kmob/apk/detail/:id()/data-personal",
    meta: data_45personalk5jASK7RAbMeta || {},
    alias: data_45personalk5jASK7RAbMeta?.alias || [],
    redirect: data_45personalk5jASK7RAbMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/data-personal.vue").then(m => m.default || m)
  },
  {
    name: emergency_45contactdIRvQIO3btMeta?.name ?? "kmob-apk-detail-id-emergency-contact",
    path: emergency_45contactdIRvQIO3btMeta?.path ?? "/kmob/apk/detail/:id()/emergency-contact",
    meta: emergency_45contactdIRvQIO3btMeta || {},
    alias: emergency_45contactdIRvQIO3btMeta?.alias || [],
    redirect: emergency_45contactdIRvQIO3btMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/emergency-contact.vue").then(m => m.default || m)
  },
  {
    name: financingTiwWaOQ6MiMeta?.name ?? "kmob-apk-detail-id-financing",
    path: financingTiwWaOQ6MiMeta?.path ?? "/kmob/apk/detail/:id()/financing",
    meta: financingTiwWaOQ6MiMeta || {},
    alias: financingTiwWaOQ6MiMeta?.alias || [],
    redirect: financingTiwWaOQ6MiMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/financing.vue").then(m => m.default || m)
  },
  {
    name: formpv_45data_45analisa_45penghasilanHPo0VVzMBYMeta?.name ?? "kmob-apk-detail-id-formpv-data-analisa-penghasilan",
    path: formpv_45data_45analisa_45penghasilanHPo0VVzMBYMeta?.path ?? "/kmob/apk/detail/:id()/formpv-data-analisa-penghasilan",
    meta: formpv_45data_45analisa_45penghasilanHPo0VVzMBYMeta || {},
    alias: formpv_45data_45analisa_45penghasilanHPo0VVzMBYMeta?.alias || [],
    redirect: formpv_45data_45analisa_45penghasilanHPo0VVzMBYMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/formpv-data-analisa-penghasilan.vue").then(m => m.default || m)
  },
  {
    name: formpv_45data_45kontak_45darurats5kR99Iu6lMeta?.name ?? "kmob-apk-detail-id-formpv-data-kontak-darurat",
    path: formpv_45data_45kontak_45darurats5kR99Iu6lMeta?.path ?? "/kmob/apk/detail/:id()/formpv-data-kontak-darurat",
    meta: formpv_45data_45kontak_45darurats5kR99Iu6lMeta || {},
    alias: formpv_45data_45kontak_45darurats5kR99Iu6lMeta?.alias || [],
    redirect: formpv_45data_45kontak_45darurats5kR99Iu6lMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/formpv-data-kontak-darurat.vue").then(m => m.default || m)
  },
  {
    name: formpv_45data_45pekerjaanBesZTvlCzLMeta?.name ?? "kmob-apk-detail-id-formpv-data-pekerjaan",
    path: formpv_45data_45pekerjaanBesZTvlCzLMeta?.path ?? "/kmob/apk/detail/:id()/formpv-data-pekerjaan",
    meta: formpv_45data_45pekerjaanBesZTvlCzLMeta || {},
    alias: formpv_45data_45pekerjaanBesZTvlCzLMeta?.alias || [],
    redirect: formpv_45data_45pekerjaanBesZTvlCzLMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/formpv-data-pekerjaan.vue").then(m => m.default || m)
  },
  {
    name: formpv_45data_45pembiayaanlSgF7cILSRMeta?.name ?? "kmob-apk-detail-id-formpv-data-pembiayaan",
    path: formpv_45data_45pembiayaanlSgF7cILSRMeta?.path ?? "/kmob/apk/detail/:id()/formpv-data-pembiayaan",
    meta: formpv_45data_45pembiayaanlSgF7cILSRMeta || {},
    alias: formpv_45data_45pembiayaanlSgF7cILSRMeta?.alias || [],
    redirect: formpv_45data_45pembiayaanlSgF7cILSRMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/formpv-data-pembiayaan.vue").then(m => m.default || m)
  },
  {
    name: formpv_45data_45personalJYANNabdeDMeta?.name ?? "kmob-apk-detail-id-formpv-data-personal",
    path: formpv_45data_45personalJYANNabdeDMeta?.path ?? "/kmob/apk/detail/:id()/formpv-data-personal",
    meta: formpv_45data_45personalJYANNabdeDMeta || {},
    alias: formpv_45data_45personalJYANNabdeDMeta?.alias || [],
    redirect: formpv_45data_45personalJYANNabdeDMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/formpv-data-personal.vue").then(m => m.default || m)
  },
  {
    name: fotokNXVD8ttPoMeta?.name ?? "kmob-apk-detail-id-foto",
    path: fotokNXVD8ttPoMeta?.path ?? "/kmob/apk/detail/:id()/foto",
    meta: fotokNXVD8ttPoMeta || {},
    alias: fotokNXVD8ttPoMeta?.alias || [],
    redirect: fotokNXVD8ttPoMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/foto.vue").then(m => m.default || m)
  },
  {
    name: indexLYmc52Ofn3Meta?.name ?? "kmob-apk-detail-id",
    path: indexLYmc52Ofn3Meta?.path ?? "/kmob/apk/detail/:id()",
    meta: indexLYmc52Ofn3Meta || {},
    alias: indexLYmc52Ofn3Meta?.alias || [],
    redirect: indexLYmc52Ofn3Meta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: job4hmJoI3sPFMeta?.name ?? "kmob-apk-detail-id-job",
    path: job4hmJoI3sPFMeta?.path ?? "/kmob/apk/detail/:id()/job",
    meta: job4hmJoI3sPFMeta || {},
    alias: job4hmJoI3sPFMeta?.alias || [],
    redirect: job4hmJoI3sPFMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/detail/[id]/job.vue").then(m => m.default || m)
  },
  {
    name: addresso9pXBw3aAmMeta?.name ?? "kmob-apk-edit-id-emergency-contact-address",
    path: addresso9pXBw3aAmMeta?.path ?? "/kmob/apk/edit/:id()/emergency-contact/address",
    meta: addresso9pXBw3aAmMeta || {},
    alias: addresso9pXBw3aAmMeta?.alias || [],
    redirect: addresso9pXBw3aAmMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/edit/[id]/emergency-contact/address.vue").then(m => m.default || m)
  },
  {
    name: contactp84WDfYnpjMeta?.name ?? "kmob-apk-edit-id-emergency-contact-contact",
    path: contactp84WDfYnpjMeta?.path ?? "/kmob/apk/edit/:id()/emergency-contact/contact",
    meta: contactp84WDfYnpjMeta || {},
    alias: contactp84WDfYnpjMeta?.alias || [],
    redirect: contactp84WDfYnpjMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/edit/[id]/emergency-contact/contact.vue").then(m => m.default || m)
  },
  {
    name: bank_45accountxskfGQRalmMeta?.name ?? "kmob-apk-edit-id-job-and-bank-account-bank-account",
    path: bank_45accountxskfGQRalmMeta?.path ?? "/kmob/apk/edit/:id()/job-and-bank-account/bank-account",
    meta: bank_45accountxskfGQRalmMeta || {},
    alias: bank_45accountxskfGQRalmMeta?.alias || [],
    redirect: bank_45accountxskfGQRalmMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/edit/[id]/job-and-bank-account/bank-account.vue").then(m => m.default || m)
  },
  {
    name: company_45addressQqIgcGQhpLMeta?.name ?? "kmob-apk-edit-id-job-and-bank-account-company-address",
    path: company_45addressQqIgcGQhpLMeta?.path ?? "/kmob/apk/edit/:id()/job-and-bank-account/company-address",
    meta: company_45addressQqIgcGQhpLMeta || {},
    alias: company_45addressQqIgcGQhpLMeta?.alias || [],
    redirect: company_45addressQqIgcGQhpLMeta?.redirect,
    component: () => import("/app/pages/kmob/apk/edit/[id]/job-and-bank-account/company-address.vue").then(m => m.default || m)
  },
  {
    name: jobGzKHOMCyL8Meta?.name ?? "kmob-apk-edit-id-job-and-bank-account-job",
    path: jobGzKHOMCyL8Meta?.path ?? "/kmob/apk/edit/:id()/job-and-bank-account/job",
    meta: jobGzKHOMCyL8Meta || {},
    alias: jobGzKHOMCyL8Meta?.alias || [],
    redirect: jobGzKHOMCyL8Meta?.redirect,
    component: () => import("/app/pages/kmob/apk/edit/[id]/job-and-bank-account/job.vue").then(m => m.default || m)
  },
  {
    name: indexDxtBHLghO6Meta?.name ?? "kmob-apk",
    path: indexDxtBHLghO6Meta?.path ?? "/kmob/apk",
    meta: indexDxtBHLghO6Meta || {},
    alias: indexDxtBHLghO6Meta?.alias || [],
    redirect: indexDxtBHLghO6Meta?.redirect,
    component: () => import("/app/pages/kmob/apk/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KETEiltJ6GMeta?.name ?? "kmob-customer-management-detail-status-id",
    path: _91id_93KETEiltJ6GMeta?.path ?? "/kmob/customer-management/detail/:status()/:id()",
    meta: _91id_93KETEiltJ6GMeta || {},
    alias: _91id_93KETEiltJ6GMeta?.alias || [],
    redirect: _91id_93KETEiltJ6GMeta?.redirect,
    component: () => import("/app/pages/kmob/customer-management/detail/[status]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNfg7x5arQEMeta?.name ?? "kmob-customer-management",
    path: indexNfg7x5arQEMeta?.path ?? "/kmob/customer-management",
    meta: indexNfg7x5arQEMeta || {},
    alias: indexNfg7x5arQEMeta?.alias || [],
    redirect: indexNfg7x5arQEMeta?.redirect,
    component: () => import("/app/pages/kmob/customer-management/index.vue").then(m => m.default || m)
  },
  {
    name: index5nm5o6xmt6Meta?.name ?? "kmob-dashboard",
    path: index5nm5o6xmt6Meta?.path ?? "/kmob/dashboard",
    meta: index5nm5o6xmt6Meta || {},
    alias: index5nm5o6xmt6Meta?.alias || [],
    redirect: index5nm5o6xmt6Meta?.redirect,
    component: () => import("/app/pages/kmob/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: contoh_45validasi5rxiiyjNyrMeta?.name ?? "kmob-order-form-contoh-validasi",
    path: contoh_45validasi5rxiiyjNyrMeta?.path ?? "/kmob/order-form/contoh-validasi",
    meta: contoh_45validasi5rxiiyjNyrMeta || {},
    alias: contoh_45validasi5rxiiyjNyrMeta?.alias || [],
    redirect: contoh_45validasi5rxiiyjNyrMeta?.redirect,
    component: () => import("/app/pages/kmob/order-form/contoh-validasi.vue").then(m => m.default || m)
  },
  {
    name: assetT1jLbBAkNSMeta?.name ?? "kmob-order-form-create-asset",
    path: assetT1jLbBAkNSMeta?.path ?? "/kmob/order-form/create/asset",
    meta: assetT1jLbBAkNSMeta || {},
    alias: assetT1jLbBAkNSMeta?.alias || [],
    redirect: assetT1jLbBAkNSMeta?.redirect,
    component: () => import("/app/pages/kmob/order-form/create/asset.vue").then(m => m.default || m)
  },
  {
    name: documentlFuKpBumcEMeta?.name ?? "kmob-order-form-create-document",
    path: documentlFuKpBumcEMeta?.path ?? "/kmob/order-form/create/document",
    meta: documentlFuKpBumcEMeta || {},
    alias: documentlFuKpBumcEMeta?.alias || [],
    redirect: documentlFuKpBumcEMeta?.redirect,
    component: () => import("/app/pages/kmob/order-form/create/document.vue").then(m => m.default || m)
  },
  {
    name: indexqA45o1kNVSMeta?.name ?? "kmob-order-form-create",
    path: indexqA45o1kNVSMeta?.path ?? "/kmob/order-form/create",
    meta: indexqA45o1kNVSMeta || {},
    alias: indexqA45o1kNVSMeta?.alias || [],
    redirect: indexqA45o1kNVSMeta?.redirect,
    component: () => import("/app/pages/kmob/order-form/create/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93etYV5xEOv9Meta?.name ?? "kmob-order-form-detail-id",
    path: _91id_93etYV5xEOv9Meta?.path ?? "/kmob/order-form/detail/:id()",
    meta: _91id_93etYV5xEOv9Meta || {},
    alias: _91id_93etYV5xEOv9Meta?.alias || [],
    redirect: _91id_93etYV5xEOv9Meta?.redirect,
    component: () => import("/app/pages/kmob/order-form/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXtMA6vXu0VMeta?.name ?? "kmob-order-form",
    path: indexXtMA6vXu0VMeta?.path ?? "/kmob/order-form",
    meta: indexXtMA6vXu0VMeta || {},
    alias: indexXtMA6vXu0VMeta?.alias || [],
    redirect: indexXtMA6vXu0VMeta?.redirect,
    component: () => import("/app/pages/kmob/order-form/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931KambrOEqwMeta?.name ?? "kmob-order-form-review-id",
    path: _91id_931KambrOEqwMeta?.path ?? "/kmob/order-form/review/:id()",
    meta: _91id_931KambrOEqwMeta || {},
    alias: _91id_931KambrOEqwMeta?.alias || [],
    redirect: _91id_931KambrOEqwMeta?.redirect,
    component: () => import("/app/pages/kmob/order-form/review/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOQLCfhnYBUMeta?.name ?? "kmob-taksasi-detail-id",
    path: indexOQLCfhnYBUMeta?.path ?? "/kmob/taksasi/detail/:id()",
    meta: indexOQLCfhnYBUMeta || {},
    alias: indexOQLCfhnYBUMeta?.alias || [],
    redirect: indexOQLCfhnYBUMeta?.redirect,
    component: () => import("/app/pages/kmob/taksasi/detail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMOI6gqxbEMeta?.name ?? "kmob-taksasi-detail-id-link-confirmation",
    path: indexPMOI6gqxbEMeta?.path ?? "/kmob/taksasi/detail/:id()/link-confirmation",
    meta: indexPMOI6gqxbEMeta || {},
    alias: indexPMOI6gqxbEMeta?.alias || [],
    redirect: indexPMOI6gqxbEMeta?.redirect,
    component: () => import("/app/pages/kmob/taksasi/detail/[id]/link-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: indexuvYFmgVDnuMeta?.name ?? "kmob-taksasi",
    path: indexuvYFmgVDnuMeta?.path ?? "/kmob/taksasi",
    meta: indexuvYFmgVDnuMeta || {},
    alias: indexuvYFmgVDnuMeta?.alias || [],
    redirect: indexuvYFmgVDnuMeta?.redirect,
    component: () => import("/app/pages/kmob/taksasi/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login",
    path: indexgK5VicK7y2Meta?.path ?? "/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93I44RHBuTq8Meta?.name ?? "nwg-customer-management-detail-status-id",
    path: _91id_93I44RHBuTq8Meta?.path ?? "/nwg/customer-management/detail/:status()/:id()",
    meta: _91id_93I44RHBuTq8Meta || {},
    alias: _91id_93I44RHBuTq8Meta?.alias || [],
    redirect: _91id_93I44RHBuTq8Meta?.redirect,
    component: () => import("/app/pages/nwg/customer-management/detail/[status]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfCLKVY6bKlMeta?.name ?? "nwg-customer-management",
    path: indexfCLKVY6bKlMeta?.path ?? "/nwg/customer-management",
    meta: indexfCLKVY6bKlMeta || {},
    alias: indexfCLKVY6bKlMeta?.alias || [],
    redirect: indexfCLKVY6bKlMeta?.redirect,
    component: () => import("/app/pages/nwg/customer-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexiXJUNZmZBAMeta?.name ?? "nwg-dashboard",
    path: indexiXJUNZmZBAMeta?.path ?? "/nwg/dashboard",
    meta: indexiXJUNZmZBAMeta || {},
    alias: indexiXJUNZmZBAMeta?.alias || [],
    redirect: indexiXJUNZmZBAMeta?.redirect,
    component: () => import("/app/pages/nwg/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wcPby857ZjMeta?.name ?? "nwg-sales-incentive-detail-id",
    path: _91id_93wcPby857ZjMeta?.path ?? "/nwg/sales-incentive/detail/:id()",
    meta: _91id_93wcPby857ZjMeta || {},
    alias: _91id_93wcPby857ZjMeta?.alias || [],
    redirect: _91id_93wcPby857ZjMeta?.redirect,
    component: () => import("/app/pages/nwg/sales-incentive/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexU0xilijXV4Meta?.name ?? "nwg-sales-incentive",
    path: indexU0xilijXV4Meta?.path ?? "/nwg/sales-incentive",
    meta: indexU0xilijXV4Meta || {},
    alias: indexU0xilijXV4Meta?.alias || [],
    redirect: indexU0xilijXV4Meta?.redirect,
    component: () => import("/app/pages/nwg/sales-incentive/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_931L7mjHLLYGMeta?.name ?? "reset-password-id-token",
    path: _91token_931L7mjHLLYGMeta?.path ?? "/reset-password/:id()/:token()",
    meta: _91token_931L7mjHLLYGMeta || {},
    alias: _91token_931L7mjHLLYGMeta?.alias || [],
    redirect: _91token_931L7mjHLLYGMeta?.redirect,
    component: () => import("/app/pages/reset-password/[id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Pr2Zd8r6Y6Meta?.name ?? "role-management-actions-id",
    path: _91id_93Pr2Zd8r6Y6Meta?.path ?? "/role-management/:actions()/:id()",
    meta: _91id_93Pr2Zd8r6Y6Meta || {},
    alias: _91id_93Pr2Zd8r6Y6Meta?.alias || [],
    redirect: _91id_93Pr2Zd8r6Y6Meta?.redirect,
    component: () => import("/app/pages/role-management/[actions]/[id].vue").then(m => m.default || m)
  },
  {
    name: createKpR9w070y5Meta?.name ?? "role-management-create",
    path: createKpR9w070y5Meta?.path ?? "/role-management/create",
    meta: createKpR9w070y5Meta || {},
    alias: createKpR9w070y5Meta?.alias || [],
    redirect: createKpR9w070y5Meta?.redirect,
    component: () => import("/app/pages/role-management/create.vue").then(m => m.default || m)
  },
  {
    name: indexIbit2heM6hMeta?.name ?? "role-management",
    path: indexIbit2heM6hMeta?.path ?? "/role-management",
    meta: indexIbit2heM6hMeta || {},
    alias: indexIbit2heM6hMeta?.alias || [],
    redirect: indexIbit2heM6hMeta?.redirect,
    component: () => import("/app/pages/role-management/index.vue").then(m => m.default || m)
  },
  {
    name: index3CIxsOSFhQMeta?.name ?? "select-lob",
    path: index3CIxsOSFhQMeta?.path ?? "/select-lob",
    meta: index3CIxsOSFhQMeta || {},
    alias: index3CIxsOSFhQMeta?.alias || [],
    redirect: index3CIxsOSFhQMeta?.redirect,
    component: () => import("/app/pages/select-lob/index.vue").then(m => m.default || m)
  },
  {
    name: createnQhgsWmMC3Meta?.name ?? "submission-form-create",
    path: createnQhgsWmMC3Meta?.path ?? "/submission-form/create",
    meta: createnQhgsWmMC3Meta || {},
    alias: createnQhgsWmMC3Meta?.alias || [],
    redirect: createnQhgsWmMC3Meta?.redirect,
    component: () => import("/app/pages/submission-form/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oq4neSi7JEMeta?.name ?? "submission-form-detail-id",
    path: _91id_93oq4neSi7JEMeta?.path ?? "/submission-form/detail/:id()",
    meta: _91id_93oq4neSi7JEMeta || {},
    alias: _91id_93oq4neSi7JEMeta?.alias || [],
    redirect: _91id_93oq4neSi7JEMeta?.redirect,
    component: () => import("/app/pages/submission-form/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexToOyMPglqDMeta?.name ?? "submission-form",
    path: indexToOyMPglqDMeta?.path ?? "/submission-form",
    meta: indexToOyMPglqDMeta || {},
    alias: indexToOyMPglqDMeta?.alias || [],
    redirect: indexToOyMPglqDMeta?.redirect,
    component: () => import("/app/pages/submission-form/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GXvhXdMk8aMeta?.name ?? "submission-form-review-kpm-id",
    path: _91id_93GXvhXdMk8aMeta?.path ?? "/submission-form/review-kpm/:id()",
    meta: _91id_93GXvhXdMk8aMeta || {},
    alias: _91id_93GXvhXdMk8aMeta?.alias || [],
    redirect: _91id_93GXvhXdMk8aMeta?.redirect,
    component: () => import("/app/pages/submission-form/review-kpm/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93STc4pdMMvTMeta?.name ?? "submission-form-review-id",
    path: _91id_93STc4pdMMvTMeta?.path ?? "/submission-form/review/:id()",
    meta: _91id_93STc4pdMMvTMeta || {},
    alias: _91id_93STc4pdMMvTMeta?.alias || [],
    redirect: _91id_93STc4pdMMvTMeta?.redirect,
    component: () => import("/app/pages/submission-form/review/[id].vue").then(m => m.default || m)
  },
  {
    name: emergency_45contactIMR2DK58DhMeta?.name ?? "survey-management-detail-id-form-branch_id-emergency-contact",
    path: emergency_45contactIMR2DK58DhMeta?.path ?? "/survey-management/detail/:id()/:form()/:branch_id()/emergency-contact",
    meta: emergency_45contactIMR2DK58DhMeta || {},
    alias: emergency_45contactIMR2DK58DhMeta?.alias || [],
    redirect: emergency_45contactIMR2DK58DhMeta?.redirect,
    component: () => import("/app/pages/survey-management/detail/[id]/[form]/[branch_id]/emergency-contact.vue").then(m => m.default || m)
  },
  {
    name: incomeNYYcMrYNOyMeta?.name ?? "survey-management-detail-id-form-branch_id-income",
    path: incomeNYYcMrYNOyMeta?.path ?? "/survey-management/detail/:id()/:form()/:branch_id()/income",
    meta: incomeNYYcMrYNOyMeta || {},
    alias: incomeNYYcMrYNOyMeta?.alias || [],
    redirect: incomeNYYcMrYNOyMeta?.redirect,
    component: () => import("/app/pages/survey-management/detail/[id]/[form]/[branch_id]/income.vue").then(m => m.default || m)
  },
  {
    name: job9WT6SGHJIXMeta?.name ?? "survey-management-detail-id-form-branch_id-job",
    path: job9WT6SGHJIXMeta?.path ?? "/survey-management/detail/:id()/:form()/:branch_id()/job",
    meta: job9WT6SGHJIXMeta || {},
    alias: job9WT6SGHJIXMeta?.alias || [],
    redirect: job9WT6SGHJIXMeta?.redirect,
    component: () => import("/app/pages/survey-management/detail/[id]/[form]/[branch_id]/job.vue").then(m => m.default || m)
  },
  {
    name: personal_45dataVy8qcPNQMHMeta?.name ?? "survey-management-detail-id-form-branch_id-personal-data",
    path: personal_45dataVy8qcPNQMHMeta?.path ?? "/survey-management/detail/:id()/:form()/:branch_id()/personal-data",
    meta: personal_45dataVy8qcPNQMHMeta || {},
    alias: personal_45dataVy8qcPNQMHMeta?.alias || [],
    redirect: personal_45dataVy8qcPNQMHMeta?.redirect,
    component: () => import("/app/pages/survey-management/detail/[id]/[form]/[branch_id]/personal-data.vue").then(m => m.default || m)
  },
  {
    name: recommendationHDFSKjUobpMeta?.name ?? "survey-management-detail-id-form-branch_id-recommendation",
    path: recommendationHDFSKjUobpMeta?.path ?? "/survey-management/detail/:id()/:form()/:branch_id()/recommendation",
    meta: recommendationHDFSKjUobpMeta || {},
    alias: recommendationHDFSKjUobpMeta?.alias || [],
    redirect: recommendationHDFSKjUobpMeta?.redirect,
    component: () => import("/app/pages/survey-management/detail/[id]/[form]/[branch_id]/recommendation.vue").then(m => m.default || m)
  },
  {
    name: assign_45editqUAURs2NdOMeta?.name ?? "survey-management-detail-id-form-assign-edit",
    path: assign_45editqUAURs2NdOMeta?.path ?? "/survey-management/detail/:id()/:form()/assign-edit",
    meta: assign_45editqUAURs2NdOMeta || {},
    alias: assign_45editqUAURs2NdOMeta?.alias || [],
    redirect: assign_45editqUAURs2NdOMeta?.redirect,
    component: () => import("/app/pages/survey-management/detail/[id]/[form]/assign-edit.vue").then(m => m.default || m)
  },
  {
    name: recalculategwb3zneh3TMeta?.name ?? "survey-management-detail-id-form-recalculate",
    path: recalculategwb3zneh3TMeta?.path ?? "/survey-management/detail/:id()/:form()/recalculate",
    meta: recalculategwb3zneh3TMeta || {},
    alias: recalculategwb3zneh3TMeta?.alias || [],
    redirect: recalculategwb3zneh3TMeta?.redirect,
    component: () => import("/app/pages/survey-management/detail/[id]/[form]/recalculate.vue").then(m => m.default || m)
  },
  {
    name: indexcF07N7W4vnMeta?.name ?? "survey-management-detail-id",
    path: indexcF07N7W4vnMeta?.path ?? "/survey-management/detail/:id()",
    meta: indexcF07N7W4vnMeta || {},
    alias: indexcF07N7W4vnMeta?.alias || [],
    redirect: indexcF07N7W4vnMeta?.redirect,
    component: () => import("/app/pages/survey-management/detail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKcr206D5SKMeta?.name ?? "survey-management",
    path: indexKcr206D5SKMeta?.path ?? "/survey-management",
    meta: indexKcr206D5SKMeta || {},
    alias: indexKcr206D5SKMeta?.alias || [],
    redirect: indexKcr206D5SKMeta?.redirect,
    component: () => import("/app/pages/survey-management/index.vue").then(m => m.default || m)
  },
  {
    name: createiLWWeZ7HCDMeta?.name ?? "user-management-create",
    path: createiLWWeZ7HCDMeta?.path ?? "/user-management/create",
    meta: createiLWWeZ7HCDMeta || {},
    alias: createiLWWeZ7HCDMeta?.alias || [],
    redirect: createiLWWeZ7HCDMeta?.redirect,
    component: () => import("/app/pages/user-management/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93myZCKEYcK8Meta?.name ?? "user-management-edit-id",
    path: _91id_93myZCKEYcK8Meta?.path ?? "/user-management/edit/:id()",
    meta: _91id_93myZCKEYcK8Meta || {},
    alias: _91id_93myZCKEYcK8Meta?.alias || [],
    redirect: _91id_93myZCKEYcK8Meta?.redirect,
    component: () => import("/app/pages/user-management/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZCMpMPW3YaMeta?.name ?? "user-management",
    path: indexZCMpMPW3YaMeta?.path ?? "/user-management",
    meta: indexZCMpMPW3YaMeta || {},
    alias: indexZCMpMPW3YaMeta?.alias || [],
    redirect: indexZCMpMPW3YaMeta?.redirect,
    component: () => import("/app/pages/user-management/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_939iIWHf4iSZMeta?.name ?? "verification-email-id-token",
    path: _91token_939iIWHf4iSZMeta?.path ?? "/verification-email/:id()/:token()",
    meta: _91token_939iIWHf4iSZMeta || {},
    alias: _91token_939iIWHf4iSZMeta?.alias || [],
    redirect: _91token_939iIWHf4iSZMeta?.redirect,
    component: () => import("/app/pages/verification-email/[id]/[token].vue").then(m => m.default || m)
  }
]